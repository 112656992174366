import React, { createContext } from 'react';
import { Archive, Description, Email } from '@mui/icons-material';
import axiosInstance from '../../../utils/axios';



export const MatterDetailsContext = createContext();

class MatterDetailsContextProvider extends React.Component {

    constructor(props){
        super(props);

        const {
            matterId,
        } = props;

        const obj = {
            isLoading: true,
            details:{},
            relatedMatters:[],
            basePath:`matter/${matterId}/`,
            accessScope:2,
            matterId,
            showCreateLetterDialogue:false,
            showCreateDocumentDialogue:false,
            digiMessageId:false,
            msgMode:'compose',
            tools:[
                {
                    iconComponent:<Email/>,
                    textKey:'sendEmail',
                    action: () => {
                        this.showCompileDialogue();
                    },
                },
                {
                    iconComponent:<Description/>,
                    textKey:'createNewLetter',
                    action: () => {
                        this.showDialogue('showCreateLetterDialogue');
                    },
                },
                {
                    iconComponent:<Description/>,
                    textKey:'createNewDocument',
                    action: () => {
                        this.showDialogue('showCreateDocumentDialogue');
                    },
                },
                {
                    textKey: 'buttonArchive',
                    iconComponent: <Archive/>,
                    action: () => {
                        this.showDialogue('showArchiveMatterDialogue');
                    },
                    branded: true
                },
            ]
        };



        this.matterId = matterId;
        this.state={...obj};
    }

    componentDidMount() {
        this.loadData();
    }
    
    hideDialogue(flag=''){
        this.setState({
            [flag]:false
        });
    }
    showDialogue(flag=''){
        console.log(`showing dialog: ${flag}`);
        this.setState({
            [flag]:true
        });
    }

    showReplyDialogue(msgId){
        this.setState({
            digiMessageId:msgId,
            msgMode:'reply',
            showSendEmailDialogue:true
        });
    }
    showForwardDialogue(msgId){
        this.setState({
            digiMessageId:msgId,
            msgMode:'forward',
            showSendEmailDialogue:true
        });
    }
    showCompileDialogue(msgId){
        this.setState({
            digiMessageId:0,
            msgMode:'compose',
            showSendEmailDialogue:true
        });
    }

    loadData(){
        this.setState({
            isLoading:true
        });



        const apiPath = `${process.env.REACT_APP_API_URL}/matterdetails/${this.matterId}`;

        axiosInstance.get(apiPath,{
            timeout: 300000,
            withCredentials:true
        }).then(res => {

            const {
                data:{
                    details={},
                    relatedMatters=[]
                }
            } = res;

            this.setState({
                isLoading:false,
                details,
                relatedMatters
            })


        }).catch(err => {
            // console.log('caught the error');
            // console.log(err);
            this.setState({
                isLoading:false,
                resTotal:0,
                results:[]
            });
        });
    }




    render(){
        return (
            <MatterDetailsContext.Provider value={{
                ...this.state,
                loadData: this.loadData.bind(this),
                setState: this.setState.bind(this),
                hideDialogue:this.hideDialogue.bind(this),
                showDialogue:this.showDialogue.bind(this),
                showReplyDialogue:this.showReplyDialogue.bind(this),
                showForwardDialogue:this.showForwardDialogue.bind(this),
                showCompileDialogue:this.showCompileDialogue.bind(this),            
            }}>
                {this.props.children}
            </MatterDetailsContext.Provider>
        );
    }


}
export default MatterDetailsContextProvider;