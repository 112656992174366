import { Box, Divider, Paper, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BrandContext } from "../../contexts/BrandContext";
import { getDefaultMatterName } from "../ReportViews/Matters/MatterUtilities";
import { es } from "date-fns/locale";
import { formatAddressToStringShort } from "../UniversalComponents";


export default function RelatedTransactions(props){

    const {
        context
    } = props;

    const brandContext = useContext(BrandContext);

    const {
        textContent:{
            relatedTransaction='',
        }
    } = brandContext;

    const {
        relatedMatters
    } = context;
    
    const navigate = useNavigate();

    

    return (
        <Paper className="relatedMattersContainer">
            <Typography variant="h3">{relatedTransaction}</Typography>
            <Box className="resultsContainer">
                {relatedMatters.map((matter,index)=>(
                    <RelatedTransResult key={index} matter={matter} />
                ))}
            </Box>
        </Paper>
    );

}



const RelatedTransResult = (props) => {
    const {
        matter:{
            matterData=null,
            estateAgencyData=null,
            matterType:{
                id:matterTypeId,
                matterDepartment:{
                    id:departmentId
                }
            }
        }
    } = props;
    if(departmentId === 1){
        if(estateAgencyData){
            return <EAResult {...props} />
        }
        return <ConveyancingResult {...props} />
    }
    if(departmentId === 3){
        return <PCResult {...props} />
    }

}

const EAResult = (props) => {
    
    const {textContent} = useContext(BrandContext);

    const {
        matter:{
            id,
            case:mainCase=null,
            matterType:{
                name:matterTypeName
            },
            estateAgencyData:{
                valuationType=null,
            }
        }
    } = props;
    
    let valuationTypeString='';
    let valuationTypeId = null;
    if(valuationType){
        ({textKey:valuationTypeString,id:valuationTypeId} = valuationType);
    }

    return (
        <Box className="resultContainer" onClick={() => {
            window.location.href=`/valuation/${id}`
        }}>
            <Box>
                <Typography variant="body1Bold">Valuation of {formatAddressToStringShort(mainCase)}</Typography>
            </Box>
            <Box>
                <Typography component="span" variant="smallText">
                    {textContent[valuationTypeString]}
                </Typography>
            </Box>
            <Divider />
        </Box>
    );
}

const ConveyancingResult = (props) => {
    const {
        matter:{
            id,
            case:mainCase=null,
            matterType:{
                name:matterTypeName
            }
        }
    } = props;

    return (
        <Box className="resultContainer">
            <a href={`https://beta.doobray.com/transactions&fn=edit&trans=${id}`} target="_blank" rel="noopener noreferrer">
                {matterTypeName} of {formatAddressToStringShort(mainCase)}
            </a>
        </Box>
    );
}

const PCResult = (props) => {
    const {
        matter:{
            id,
            matterData,
            matterUserRoles=[]
        },
        matter
    } = props;
                  
    
    let dueDate = '';
    let matterName = '';
    if(matterData){
        ({dueDate,matterName} = matterData);
    } 
    if(matterName === '' || matterName === null){
        matterName = getDefaultMatterName(matter);
    }
    let dueDateFormatted = '';
    if(dueDate){
        const dateObj = new Date(dueDate);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        dueDateFormatted = new Intl.DateTimeFormat('en-GB', options).format(dateObj);
    }

    const getFirstCharacter = (inputString) => inputString.length > 0 ? inputString[0].toUpperCase() : '';

    return (
        <Box className="resultContainer" onClick={() => {
            window.location.href=`/matter/${id}`
        }}>
            <Box><Typography variant="body1Bold">{matterName}</Typography></Box>
            <Box>
                {matterUserRoles.map((userRole,index)=>{
                    const{
                        bdUser:{
                            firstName,
                            lastName,
                            userFirmsLinks=[]
                        }
                    } = userRole;
                    let initials = '';
                    if(userFirmsLinks.length > 0){
                        const {
                            customInitials=''
                        } = userFirmsLinks[0];
                        if(customInitials !== ''){
                            initials = customInitials;
                        }
                    }
                    if(!initials){
                        initials = `${getFirstCharacter(firstName)}${getFirstCharacter(lastName)}`;
                    }
                    const isLast = index === matterUserRoles.length-1;
                    return (
                        <Typography key={index} component="span" variant="smallText">
                            {initials}{!isLast && '/'}
                        </Typography>
                    )

                })}
            </Box>
            <Box>
                <Typography variant="smallText">{dueDateFormatted}</Typography>
            </Box>
            <Divider/>
        </Box>
    );
}