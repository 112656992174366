import React, { createContext } from 'react';
import { Archive, Description, Email } from '@mui/icons-material';
import axiosInstance from '../../../utils/axios';



export const ValuationDetailsContext = createContext();

class ValuationDetailsContextProvider extends React.Component {

    constructor(props){
        super(props);
        
        const {
            matterId,
        } = props;
        
        const obj = {
            isLoading: true,
            details:{},
            relatedMatters:[],
            basePath:`valuation/${matterId}/`,
            accessScope:3,
            matterId,
            showCreateLetterDialogue:false,
            showCreateDocumentDialogue:false,
            tools:[
                {
                    iconComponent:<Email/>,
                    textKey:'sendEmail',
                    action: () => {
                        this.showDialogue('showSendEmailDialogue');
                    },
                },
                {
                    iconComponent:<Description/>,
                    textKey:'createNewLetter',
                    action: () => {
                        this.showDialogue('showCreateLetterDialogue');
                    },
                },
                {
                    iconComponent:<Description/>,
                    textKey:'createNewDocument',
                    action: () => {
                        this.showDialogue('showCreateDocumentDialogue');
                    },
                },
                {
                    textKey: 'buttonArchive',
                    iconComponent: <Archive/>,
                    action: () => {
                        this.showDialogue('showArchiveMatterDialogue');
                    },
                    branded: true
                },
            ]
        };

        console.log(props);

        this.matterId = matterId;
        this.state={...obj};
    }

    componentDidMount() {
        this.loadData();
    }
    
    hideDialogue(flag=''){
        this.setState({
            [flag]:false
        });
    }
    showDialogue(flag=''){
        this.setState({
            [flag]:true
        });
    }

    loadData(){
        this.setState({
            isLoading:true
        });

        console.log(this.matterId);

        const apiPath = `${process.env.REACT_APP_API_URL}/valuationdetails/${this.matterId}`;

        axiosInstance.get(apiPath,{
            timeout: 300000,
            withCredentials:true
        }).then(res => {

            const {
                data:{
                    details={},
                    latlng={},
                    relatedMatters=[]
                }
            } = res;

            this.setState({
                isLoading:false,
                details,
                latlng,
                relatedMatters
            })


        }).catch(err => {
            // console.log('caught the error');
            // console.log(err);
            this.setState({
                isLoading:false,
                resTotal:0,
                results:[]
            });
        });
    }




    render(){
        return (
            <ValuationDetailsContext.Provider value={{
                ...this.state,
                loadData: this.loadData.bind(this),
                setState: this.setState.bind(this),
                hideDialogue:this.hideDialogue.bind(this),
                showDialogue:this.hideDialogue.bind(this),            
            }}>
                {this.props.children}
            </ValuationDetailsContext.Provider>
        );
    }


}
export default ValuationDetailsContextProvider;